import { storeToRefs } from "pinia";
import { useUserStore } from "@/store/SiteUser";

export default defineNuxtRouteMiddleware((_from) => {
  const userStore = useUserStore();
  const userData = storeToRefs(userStore);
  if (userData.loggedIn.value) {
    return navigateTo("/", { redirectCode: 301 });
  }
});
